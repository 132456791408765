// /**
//  * Axios config  setup
//  * Set interceptor for global api response error handling
//  * Set access token in headers
//  */
import axios from "axios";
import store from "../Redux/store"
import { logout } from "../Redux/login/Action";

(function (axios) {

  const token = JSON.parse(localStorage.getItem("authTokenWebsite"));
  const userData = JSON.parse(localStorage.getItem("userWebsite"));
  
  if (token) {
    axios.defaults.headers.common['authorization'] = token;
  } else {
    axios.defaults.headers.common['authorization'] = '';
  }

  axios.interceptors.response.use(null, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(logout({ deviceId: userData.deviceId }));
        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!"
          }
        }
      }
      return Promise.reject(err);
    }
  });
})(axios);