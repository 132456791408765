import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'

// To Get About Us Link
export const getResourseList = () => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getHomePage`,)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_RESOURSES_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_RESOURSES_LIST_ERROR, payload: err.response })
            throw err
        })
}

// To Get Tag Name
export const getTagNames = () => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getAllTags`, {})
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_TAG_NAME_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_TAG_NAME_ERROR, payload: err.response })
            throw err
        })
}

// To Get AutoComplete
export const getAutoComplete = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/autoComplete`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_AUTO_COMPLETE_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_AUTO_COMPLETE_ERROR, payload: err.response })
            throw err
        })
}

// To Get Search Data
export const getSearchData = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getSearchData`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_SEARCH_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_SEARCH_LIST_ERROR, payload: err.response })
            throw err
        })
}
// To Get View More Data
export const getViewMoreData = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/viewMoreHomeSubCategories`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_VIEW_MORE_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_VIEW_MORE_LIST_ERROR, payload: err.response })
            throw err
        })
}

// To Get View More Data
export const getWorkShopResourceData = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/viewMoreHomeSubCategories`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_WORKSHOP_RESOURCE_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_WORKSHOP_RESOURCE_LIST_ERROR, payload: err.response })
            throw err
        })
}

// To get terms and conditions
export const getTermsConditions = () => dispatch => {
    return axios
        .post(`${API_BASE}auth/getAppSettings`, {})
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_TERMS_CONDITION_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_TERMS_CONDITION_ERROR, payload: err.response })
            throw err
        })
}
