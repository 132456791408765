import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from './components/layout/';
import CreateAcc from './pages/CreateAcc';
import CreateAccNext from './pages/CreateAccNext';
import SignIn from './pages/SignIn';
import checkAuth from './Authentication/Auth'
import './config/AxiosConfig'
import { authenticate } from './Redux/login/Action';
import { AUTHENTICATE_FAILED } from './Redux/login/Types';
import store from './Redux/store';
import ForgotPassword from './pages/ForgotPassword';


const Loading = () => <div className="pt-3 text-center">Loading...</div>

const ScrollToTop = React.lazy(() => import('./components/common/scroll'));

const token = JSON.parse(localStorage.getItem("authTokenWebsite"));
const loggedInUser = JSON.parse(localStorage.getItem("userWebsite"));

if (token && loggedInUser) {
  store.dispatch(authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense fallback={Loading()}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/signin" name="Page SignIn" component={(SignIn)} />
            <Route exact path="/signin/:path" name="Page SignIn" component={(SignIn)} />
            <Route exact path="/forgot-password" name="Forgot password" component={(ForgotPassword)} />
            <Route exact path="/create-account" name="Page CreateAcc" component={(CreateAcc)} />
            <Route exact path="/create-account-next" name="Page CreateAccNext" component={(CreateAccNext)} />
            <Route path="/" component={checkAuth(Layout)} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}
export default App;