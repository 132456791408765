import * as ACTION_TYPES from './Types';

const initialState = {
  subCategoryDetail: { typeData: { resourceTags: [], bookmarked: undefined, resources: [] } },
  workShop: { typeData: [] },

  message: '',
  error: null
}

export const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategoryDetail: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_SUBCATEGORY_ERROR:
      return {
        ...state,
        subCategoryDetail: { typeData: { resourceTags: [], bookmarked: undefined, resources: [] } }
      }
    case ACTION_TYPES.GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workShop: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_WORKSHOP_ERROR:
      return {
        ...state,
        workShop: { typeData: [] },
        message: action.payload.message,
        error: null
      }

    default:
      return state;
  }
}
