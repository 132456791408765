import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'


// To Get Premium Resource List
export const getPremiumResourseList = () => dispatch => {
    return axios
        .post(`${API_BASE}webapi/listPremiumResources`, {})
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_PREMIUM_RESOURSES_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_PREMIUM_RESOURSES_LIST_ERROR, payload: err.response })
            throw err
        })
}


// To Get puchased resource list
export const getPurchasedPremiumList = (userId) => dispatch => {
    return axios
        .get(`${API_BASE}webapi/getPurchasedPremuimResources?userId=${userId}`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_PURCHASED_RESOURCE_LIST_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_PURCHASED_RESOURCE_LIST_ERROR, payload: err.response })
            throw err
        })
}

// To Get Un purchased Premium Resource Info
export const getPremiumResourceInfo = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getPremiumResourceDetails`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_UNPURCHASED_RESOURCE_INFO_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_UNPURCHASED_RESOURCE_INFO_ERROR, payload: err.response })
            throw err
        })
}

// To Get purchased Premium Resource Info
export const getPurchasedPremiumResourceInfo = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getPremiumResourceDetails`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_PURCHASED_RESOURCE_INFO_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_PURCHASED_RESOURCE_INFO_ERROR, payload: err.response })
            throw err
        })
}


// To Get Card Info
export const getCardInfo = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getAllCards`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_CARD_INFO_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_CARD_INFO_ERROR, payload: err.response })
            throw err
        })
}

// To Add Card Info
export const addCard = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/addCard`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Buy Now with unsaved Card 
export const toBuyWithUnsavedCard = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/makePaymentWithUnSavedCard`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Buy Now with saved Card 
export const toBuyWithSavedCard = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/makePaymentWithSavedCard`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Delete saved Card 
export const toDeleteCard = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/deleteCard`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Change Default Card 
export const toChangeDefaultCard = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/changeDefaultCard`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Get Cart
export const getCart = (userId) => dispatch => {
    return axios
        .get(`${API_BASE}webapi/getCartItemList?userId=${userId}`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_CART_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_CART_ERROR, payload: err.response })
            throw err
        })
}

// To add to Cart
export const addToCart = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/addToCart`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// to bookmark premium resource
export const toBookmarkPremiumResource = data => dispatch => {
    return axios
        .post(`${API_BASE}webapi/bookmarkResource2`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// to check weather resource is purchased or not
export const toCheckIsPurchased = data => dispatch => {
    return axios
        .post(`${API_BASE}webapi/checkIsPurchased`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// to delete resource from cart
export const toDeleteCartResource = data => dispatch => {
    return axios
        .post(`${API_BASE}webapi/removeFromCart`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To get downloadable resource in workshop page 
export const getDownloadAbleResouces = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getDownloadAbleResouces`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To get downloadable resource in workshop page 
export const toDownloadVideo = (data) => dispatch => {
    return axios
        .get(`${API_BASE}auth/downloadVideo?resourceId=${data}`)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}
