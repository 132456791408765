//Get Resourse info
export const GET_RESOURSES_LIST_SUCCESS = "GET_RESOURSES_LIST_SUCCESS";
export const GET_RESOURSES_LIST_ERROR = "GET_RESOURSES_LIST_ERROR";

//Get Resourse info
export const GET_RESOURSE_INFO_SUCCESS = "GET_RESOURSE_INFO_SUCCESS";
export const GET_RESOURSE_INFO_ERROR = "GET_RESOURSE_INFO_ERROR";

//Get Resourse info
export const GET_TAG_NAME_SUCCESS = "GET_TAG_NAME_SUCCESS";
export const GET_TAG_NAME_ERROR = "GET_TAG_NAME_ERROR";

//Get Auto Complete info
export const GET_AUTO_COMPLETE_SUCCESS = "GET_AUTO_COMPLETE_SUCCESS";
export const GET_AUTO_COMPLETE_ERROR = "GET_AUTO_COMPLETE_ERROR";

//Get Search List
export const GET_SEARCH_LIST_SUCCESS = "GET_SEARCH_LIST_SUCCESS";
export const GET_SEARCH_LIST_ERROR = "GET_SEARCH_LIST_ERROR";

//Get view More  List
export const GET_VIEW_MORE_LIST_SUCCESS = "GET_VIEW_MORE_LIST_SUCCESS";
export const GET_VIEW_MORE_LIST_ERROR = "GET_VIEW_MORE_LIST_ERROR";

//Get workshop resource  List
export const GET_WORKSHOP_RESOURCE_LIST_SUCCESS = "GET_WORKSHOP_RESOURCE_LIST_SUCCESS";
export const GET_WORKSHOP_RESOURCE_LIST_ERROR = "GET_WORKSHOP_RESOURCE_LIST_ERROR";

//Get Terms and conditions
export const GET_TERMS_CONDITION_SUCCESS = "GET_TERMS_CONDITION_SUCCESS";
export const GET_TERMS_CONDITION_ERROR = "GET_TERMS_CONDITION_ERROR";
