import * as ACTION_TYPES from './Types';

const initialState = {
  preimumResources: { recordsTotal: 0, Resources: [] },
  purchasedPreimumResources: { recordsTotal: 0, Resources: [] },
  cardInfo: [],
  unPurchasedPremiumResourceInfo: { url: [] },

  purchasedPremiumResourceInfo: { tags: [], resources: [], pdfs: [] },
  purchasedPremiumWorkshopInfo: { resources: [] },

  cart: { resourceDetails: [] },

  subCategoryDetail: { typeData: { resourceTags: [], bookmarked: undefined, resources: [] } },
  workShop: { typeData: [] },


  message: '',
  error: null
}

export const premiumResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_PREMIUM_RESOURSES_LIST_SUCCESS:
      return {
        ...state,
        preimumResources: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PREMIUM_RESOURSES_LIST_ERROR:
      return {
        ...state,
        preimumResources: { recordsTotal: 0, Resources: [] }
      }
    case ACTION_TYPES.GET_UNPURCHASED_RESOURCE_INFO_SUCCESS:
      return {
        ...state,
        unPurchasedPremiumResourceInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_UNPURCHASED_RESOURCE_INFO_ERROR:
      return {
        ...state,
        unPurchasedPremiumResourceInfo: { url: [], resources: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_CARD_INFO_SUCCESS:
      return {
        ...state,
        cardInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_CARD_INFO_ERROR:
      return {
        ...state,
        cardInfo: [],
        error: null
      }
    case ACTION_TYPES.GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.data ? action.payload.data : { resourceDetails: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_CART_ERROR:
      return {
        ...state,
        cart: { resourceDetails: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PURCHASED_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        purchasedPreimumResources: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PURCHASED_RESOURCE_LIST_ERROR:
      return {
        ...state,
        purchasedPreimumResources: { recordsTotal: 0, Resources: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PURCHASED_RESOURCE_INFO_SUCCESS:
      return {
        ...state,
        purchasedPremiumResourceInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PURCHASED_RESOURCE_INFO_ERROR:
      return {
        ...state,
        purchasedPremiumResourceInfo: { tags: [], resources: [], pdfs: [] },
        message: action.payload.message,
        error: null
      }



    case ACTION_TYPES.GET_PREMIUM_WORKSHOP_INFO_SUCCESS:
      return {
        ...state,
        purchasedPremiumWorkshopInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PREMIUM_WORKSHOP_INFO_ERROR:
      return {
        ...state,
        purchasedPremiumWorkshopInfo: { resources: [] },
        message: action.payload.message,
        error: null
      }

    default:
      return state;
  }
}
