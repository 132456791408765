import React from 'react';
import NumberFormat from 'react-number-format';
import { toAbsoluteUrl } from "../../utils";

export const renderInputField = ({
  input,
  type,
  className,
  placeholder,
  disabled,
  readOnly,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <label className="upload-label">
        <input
          {...input}
          className={className}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
      </label>
      <div className="text-error text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderSelectField = ({
  input,
  disabled,
  options,
  placeholder,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <select {...input} style={{ backgroundImage: `url(${toAbsoluteUrl('/images/np_arrow_down.svg')})` }}>
        <option value=''>Select {placeholder}</option>
        {options.length > 0 && options.map((val, i) => {
          return <option value={val.name} key={i}>{val.name}</option>
        })}
      </select>
      <div className="text-error text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderFileField = ({
  input,
  picture,
  className,
  refType,
  type,
  meta: {
    touched,
    error
  }
}) => {
  return (<>
    {picture.src &&
      <figure className={className}>
        <img src={picture.src} alt={picture.alt} height="80px" />
      </figure>
    }
    <input className={className} ref={refType}  {...input} type={type} accept="image/jpeg,image/png,image/jpg" value={undefined} />
    <div className="text-danger">
      {/* {(touched && error) && } */}
    </div>
  </>)
}

export const renderNumberField = ({
  input,
  type,
  className,
  placeholder,
  disabled,
  readOnly,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <label className="upload-label">
        <NumberFormat {...input} format="(###) ###-####" placeholder="Phone Number" />
      </label>
      <div className="text-error text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderProfileInputField = ({
  input,
  type,
  className,
  placeholder,
  disabled,
  labelName,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <label>{labelName}</label>
      <input
        {...input}
        className={className ? className : ''}
        type={type}
        disabled={disabled}
        placeholder={placeholder} />
      <div className="text-error text-right text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderProfileNumberField = ({
  input,
  disabled,
  labelName,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <label>{labelName}</label>
      <NumberFormat  {...input}
        format="(###) ###-####"
        disabled={disabled}
        // mask="_"
        placeholder="Phone Number" />
      <div className="text-error text-right text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderProfileSelectField = ({
  input,
  disabled,
  options,
  labelName,
  placeholder,
  bgImage,
  className,
  meta: { touched, error }
}) => {
  return (<>
    <li className="form-group">
      <label>{labelName}</label>
      <select {...input} disabled={disabled} style={{ backgroundImage: `url(${toAbsoluteUrl(bgImage)})` }} className={className}>
        <option value=''>Select {placeholder}</option>
        {options.length > 0 && options.map((val, i) => {
          return <option value={val.name} key={i}>{val.name}</option>
        })}
      </select>
      <div className="text-error text-right text-danger">
        {(touched && error) && error}
      </div>
    </li>
  </>);
};

export const renderCardDetailInputField = ({
  input,
  type,
  labelName,
  placeholder,
  disabled,
  divClass,
  meta: { touched, error }
}) => {
  return (<>
    <div className={divClass}>
      <label>{labelName}</label>
      <input {...input} type={type} disabled={disabled} placeholder={placeholder} />
      <div className="text-error text-danger text-right">{(touched && error) && error}</div>
    </div>
    {/* <div className="text-error text-danger">

    </div> */}
  </>);
};

export const renderCardNumberField = ({
  input,
  disabled,
  labelName,
  divClass,
  formatField,
  placeholder,
  styleField,
  meta: { touched, error }
}) => {
  return (<>
    <div className={divClass}>
      <label>{labelName}</label>
      <NumberFormat  {...input}
        format={formatField}
        style={styleField}
        disabled={disabled}
        placeholder={placeholder} />
      <div className="text-error text-danger text-right">{(touched && error) && error}</div>
    </div>
    {/* <div className="text-error text-right text-danger">
      {(touched && error) && error}
    </div> */}
  </>);
};

