import React from 'react';
import { Helmet } from "react-helmet";
import Button from '@material-ui/core/Button';
import "../scss/pages/signin.scss";
import { Field, reduxForm } from 'redux-form';
import { renderInputField } from '../components/common/FormField';
import { createAcc as validate } from '../validate/validate';
import { useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from "../utils";

const TITLE = "LMM :: Create Account"

const CreateAcc = ({ handleSubmit }) => {

  const history = useHistory();
  const isLoginState = useSelector(state => state.login.isLoggedIn)

  const onFormSubmit = (data) => {
    history.push('/create-account-next')
  }

  if (isLoginState)
    return <Redirect to="/" />

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <section className="accountsec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 login-box create-account">
                <div className="login-content d-flex align-items-center justify-content-center flex-column">
                  <div className="acc-header-top d-flex justify-content-between">
                    <Link to='/webapp/signin'><img src={toAbsoluteUrl("/images/np_arrow_white_back.svg")} alt="" /></Link>
                    <div className="acc-help">
                      <img src={toAbsoluteUrl("/images/np_question.svg")} alt="" />
                      <a href="mailto:support@legacymindedmen.org">Help</a>
                    </div>
                  </div>
                  <div className="acc-header">
                    <h3 className="logintitle">Create Account</h3>
                  </div>
                  <div className="login-box-inner">
                    <ul>
                      <Field name="email"
                        type="text"
                        component={renderInputField}
                        placeholder="Email"
                        required />
                      <Field name="confirmEmail"
                        type="email"
                        component={renderInputField}
                        placeholder="Confirm Email"
                        required />
                      <Field name="password"
                        type="password"
                        component={renderInputField}
                        placeholder="Password"
                        required />
                      <Field name="confirmPassword"
                        type="password"
                        component={renderInputField}
                        placeholder="Confirm Password"
                        required />
                    </ul>
                  </div>
                  <div className="acc-footer">
                    <Button className="btn" type="submit">Next</Button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 create-account-img login-box-content d-flex align-items-start justify-content-end" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/engage-img.png')})` }}>
                <div className="login-content-inner">
                  <h3>ENGAGE</h3>
                  <h3>EQUIP</h3>
                  <h3>ENCOURAGE</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  )
}
export default reduxForm({
  form: 'createAcc',
  validate,
  destroyOnUnmount: false
})(CreateAcc);